<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="30%"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeDepartmentDialog('departmentEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="department"
                    :rules="rules"
                    ref="departmentEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="部门名称" prop="name">
                            <el-input
                                v-model="department.name"
                                clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="所属企业" prop="enterprise_id">
                            <el-select
                                v-model="department.enterprise_id"
                                placeholder="选择企业"
                                @change="(e) => enterpriseChange(e)"
                                class="enterprisec"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="enterprise in fAllenterprise"
                                    :key="enterprise.name"
                                    :label="enterprise.name"
                                    :value="enterprise.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="this.id" label="负责人" prop="name">
                            <el-select
                                v-model="department.director"
                                filterable
                                placeholder="请选择负责人"
                                class="directorc"
                            >
                                <el-option
                                    v-for="item in options"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="状态" prop="status">
                            <el-select
                                v-model="department.status"
                                filterable
                                placeholder="请选择状态"
                                class="statusc"
                            >
                                <el-option
                                    v-for="(v,i) in ['禁用','启用']"
                                    :key="i"
                                    :label="v"
                                    :value="i"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>部门名称</span>
                                </template>
                                <span>{{department.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>所属企业</span>
                                </template>
                                <span>{{department.enterprise_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>负责人</span>
                                </template>
                                <span>{{department.director_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>状态</span>
                                </template>
                                <el-tag v-if="department.status === 0" type="danger">禁用</el-tag>
                                <el-tag v-else-if="department.status === 1" type="success">可用</el-tag>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="departmentDel('departmentEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeDepartmentDialog('departmentEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('departmentEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('departmentEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDepartmentDialog('departmentEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('departmentEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDepartmentDialog('departmentEditForm')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { department_add_request,department_del_request,department_edit_request } from '@/network/department.js'

export default {
    name: 'DepartmentEditComponent',
    data() {
        return {
            login_loading: false,
            department: {
                name: '',
                enterprise_id: '',
                enterprise_name: '',
                director: '',
                director_name: '',
                status: 1
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入部门名称',
                        trigger: 'blur',
                    },
                    {
                        min: 2,
                        max: 16,
                        message: '长度在 2 到 16 个字符',
                        trigger: 'blur',
                    },
                ],
            },
            options: [],
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '部门详情'
            } else if (this.show_type === 1) {
                return '添加部门'
            } else {
                return '修改部门'
            }
        },
    },
    methods: {
        closeDepartmentDialog(formName) {
            this.department = {
                name: '',
                enterprise_id: '',
                enterprise_name: '',
                director: '',
                director_name: '',
                status: 1,
            }
            this.$emit('exitDepartmentDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.departmentInsert(formName)
            })
        },
        departmentInsert(formName) {
            this.login_loading = true
            department_add_request(this.department)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDepartmentDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.department = this.department = {
                name: '',
                enterprise_id: '',
                enterprise_name: '',
                director: '',
                director_name: '',
                status: 1
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.departmentEdit(formName)
            })
        },
        departmentEdit(formName) {
            this.login_loading = true
            this.department.id = this.id
            department_edit_request(this.department)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDepartmentDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.department.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        departmentDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    department_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDepartmentDialog(formName))
                })
                .catch((err) => {})
        },
        enterpriseChange(e) {
            this.department.director = ''
            this.$emit('fenterpriseChange', e)
        },
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        department_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        foptions: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        fAllenterprise: Array,
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        department_details_data(newVal) {
            this.department = newVal
        },
        foptions(newVal) {
            this.options = newVal
        }
    },
}
</script>

<style>
.enterprisec,.directorc,.statusc {
    width: 100%;
}
</style>