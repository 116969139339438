import request from './request'

//部门列表
export const department_index_request = p => {
    return request({
        method:'GET',
        url:'department/index',
        params: p
    })
}

//添加部门
export const department_add_request = d => {
    return request({
        method:'POST',
        url:'department/add',
        data: d
    })
}

//删除部门
export const department_del_request = id => {
    return request({
        method:'DELETE',
        url:'department/del',
        data: {
            id: id
        }
    })
}

//修改部门
export const department_edit_request = data => {
    return request({
        method:'PUT',
        url:'department/edit',
        data
    })
}

//获取部门详情
export const department_details_request = id => {
    return request({
        method:'GET',
        url:'department/details',
        params: {
            id
        }
    })
}